import { Container } from './Container';
import { ElementRef, AfterViewInit, Input, Directive } from '@angular/core';

@Directive()
export abstract class FlexContainer extends Container implements AfterViewInit {

  @Input() protected justifyContent: string;
  @Input() protected alignItems: string;
  @Input() protected flexGrow: string;
  @Input() protected flexDirection: string;
  @Input() protected flexWrap: string;

  constructor(element: ElementRef) {
    super(element);

    // get style object
    const style = element.nativeElement.style;

    // set appearance properties
    style.display = 'flex';
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // set flex properties
    this.setFlexProperties();
  }

  setFlexProperties() {
    // get style object
    const style = this.ref.nativeElement.style;

    if (this.justifyContent) {
      style.justifyContent = this.justifyContent;
    }

    if (this.flexWrap) {
      style.flexWrap = this.flexWrap;
    }

    if (this.alignItems) {
      style.alignItems = this.alignItems;
    }

    if (this.flexGrow) {
      style.flexGrow = this.flexGrow;
    }

    if (this.flexDirection) {
      style.flexDirection = this.flexDirection;
    }
  }
}
