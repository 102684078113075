import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  colors = {
    GRAY: 'gray',
    WHITE: 'white',
    BLUE: '#3cb1fd',
    INPUT_GRAY: '#eeeeee',
    BACK_GRAY: '#cccccc',
    BLACK: 'black',
    DARK_BLUE: '#4f555e',
    LIGHT_BLACK: '#333',
    DIM_BLACK: '#141414'
  };

  constructor() { }
}
