import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[wnVoid]'
})
export class VoidDirective implements AfterViewInit {

  @Input() height: string;

  ref: ElementRef;

  constructor(element: ElementRef) {
    this.ref = element;
  }

  ngAfterViewInit(): void {
    // set height
    this.setHeight();
  }

  private setHeight(): void {
    // get style object
    const style = this.ref.nativeElement.style;

    // set height
    style.height = this.height;
  }
}
