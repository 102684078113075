import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wn-wtv-max-slider',
  templateUrl: './wtv-max-slider.component.html',
  styleUrls: ['./wtv-max-slider.component.scss']
})
export class WtvMaxSliderComponent implements OnInit {
  @Input() content: any[];

  isVisible = false;
  constructor() { }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  ngOnInit(): void {
  }

}
