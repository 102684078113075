<div wnFlexContainer *ngIf="title" [style.padding-top]="topSpace" [backgroundColor]="bgColor" [justifyContent]="'left'" [alignItems]="'center'">
    <div wnText [style.padding-left]="'7%'" [color]="color" [fontWeight]="'800'" [fontSize]="'34px'" [style.letterSpacing]="'1px'">{{title}}
    </div>
</div>

<div class="relative">
    <div class="mainflix" (scroll)="horizScroll($event)" #horizScrollElem>
        <!-- overlay gradient -->
        <div *ngIf="!hideGradient" id="overlay-gradient" wnFlexContainer [height]="'100%'" [width]="'100%'"></div>

        <div [style.height]="height" class="mainflix-item" *ngFor="let item of lifeStyle">
            <div class="image-container">
                <img class="im" [src]="item.pic">
                <!-- We see the future -->
                <div *ngIf="show" wnFlexContainer id="tag-line" [height]="'100%'" [width]="'100%'" [justifyContent]="'center'" [alignItems]="'center'">
                    <div wnText [color]="uiService.colors.BLUE">{{item.name}}</div>
                </div>
            </div>

            <!--logos  -->
            <div *ngIf="show" wnFlexContainer id="tag-line" [height]="'100%'" [width]="'100%'" [justifyContent]="'center'" [alignItems]="'flex-end'">
                <div class="logo-pos ex">
                    <img class="logo-pos" src="assets/excel.png" alt="">
                </div>
                <div class="logo-pos"><img class="logo-bottom" src="assets/vantage.png" alt=""></div>
                <div class="logo-pos"><img class="logo-bottom" src="assets/cima.png" alt=""></div>
            </div>
        </div>

        <!-- controllers -->
        <div wnFlexContainer class="controllers" id="left" [justifyContent]="'center'" [alignItems]="'center'" [hidden]="scrollLeft === 0">
            <div wnText [color]="uiService.colors.WHITE" (click)="moveScrollLeft()">PREV</div>
        </div>

        <div wnFlexContainer class="controllers" id="right" [justifyContent]="'center'" [alignItems]="'center'" [hidden]="((scrollWidth - scrollOffsetWidth) - scrollLeft) < 1">
            <div wnText [color]="uiService.colors.WHITE" (click)="moveScrollRight()">NEXT</div>
        </div>
