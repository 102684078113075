import { Component, Input, OnInit } from '@angular/core';
import { Observable, interval } from 'rxjs';

@Component({
  selector: 'wn-wtv-hero',
  templateUrl: './wtv-hero.component.html',
  styleUrls: ['./wtv-hero.component.scss']
})
export class WtvHeroComponent implements OnInit {

  @Input() content: any[];

  show = false
  play; mute

  changeText
  constructor() { }

  ngOnInit(): void {
    // this.sub = Observable.interval(10000)
    // .subscribe((val) => { console.log('called'); });
  }


showSound() {
  this.show = !this.show;
}
  async pauseOrPlay(video, play: boolean, delay: number = 0) {
    await this.delay(delay);
    play ? video.play() : video.pause() ;
    this.mute ? video.muted = "muted" : video.muted = !"muted"
    this.play = play;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }




}
