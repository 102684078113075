import { Component, OnInit } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
@Component({
  selector: 'wn-wtv-nav',
  templateUrl: './wtv-nav.component.html',
  styleUrls: ['./wtv-nav.component.scss']
})
export class WtvNavComponent implements OnInit {
  visible = false;
  placement: NzDrawerPlacement = 'left';
  constructor() { }

  ngOnInit(): void {
    var navbar = document.querySelector('nav')
    window.onscroll = function() {

      // pageYOffset or scrollY
      if (window.pageYOffset > 0) {
        navbar.classList.add('scrolled')
      } else {
        navbar.classList.remove('scrolled')
      }
    }
  }


  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

}
