import { Directive, ElementRef } from '@angular/core';
import { FlexContainer } from '../classes/FlexContainer';

@Directive({
  selector: '[wnFlexContainer]'
})
export class FlexContainerDirective extends FlexContainer {

  constructor(element: ElementRef) {
    super(element);
  }


}
