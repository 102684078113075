import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Subscription } from 'rxjs';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { UiService } from 'src/app/services/ui/ui.service';
// import { UiService } from './../../../../services/ui/ui.service';
@Component({
  selector: 'wn-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit{

  @Input() images: string[];
  @Input() content: any[];
  @Input() text?: string[];
  @Input() hideGradient?: boolean;
  @Input() show: string;
  @Input() height: string;
  @Input() mini: string;
  thumbUrl: string = "";
  thumbImage: string = "";
  isVisible = false;
  view;
  // states
  currentIndex: BehaviorSubject<number> = new BehaviorSubject(0);


  scrollLeft: number;
  scrollWidth: number;
  scrollOffsetWidth: number;
  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;


  private sub: Subscription;
  constructor(public uiService: UiService) { }

  ngOnInit(): void {
  }

  pre(){
    this.myCarousel.pre();
  }
  next() {
    console.log(this.myCarousel.activeIndex)
    this.myCarousel.next();
  }

  onShow(hashed_id: string): void {
    console.log('Button show clicked!');
    this.view = true;
    this.thumbUrl = `wistia_embed wistia_async_${hashed_id}  videoFoam=true`
    this.thumbImage = `https://fast.wistia.com/embed/medias/${hashed_id}/swatch`

  }
  cancel(): void {
    console.log('Button cancel clicked!');
    this.view = false;
  }
}
