<nav class="flex z-1 top-0">
  <i
    class="draw"
    (click)="open()"
    nz-icon
    nzType="menu"
    nzTheme="outline"
    style="margin-left: 15px"
  ></i>
  <div class="flex justify-between items-center">
    <div class="flex menu">
      <div
        routerLink="'/home'"
        routerLinkActive="router-link-active"
        class="align-self"
      >
        HOME
      </div>
    </div>
  </div>
  <div class="absolute log">
    <img class="logo" src="assets/images/logo/qtv_white.png" alt="" />
  </div>
  <nz-drawer
    [nzClosable]="false"
    [nzVisible]="visible"
    [nzPlacement]="placement"
    (nzOnClose)="close()"
  >
    <ng-container *nzDrawerContent>
      <div
        routerLink="'/home'"
        routerLinkActive="router-link-active"
        class="align-self"
      >
        HOME
      </div>
    </ng-container>
  </nz-drawer>
</nav>
