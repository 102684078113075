<div class="relative">
    <nz-carousel nzAutoPlay [nzEffect]="'fade'" [nzAutoPlaySpeed]="5000" [nzEnableSwipe]="true">
        <!-- overlay gradient -->
        <div class="gradient" *ngIf="!hideGradient" id="overlay-gradient" wnFlexContainer [height]="'100%'" [width]="'100%'"></div>
        <div class="relative" nz-carousel-content *ngFor="let index of content">
            <img class="im" [src]="index.pic" alt="">

        </div>

    </nz-carousel>



    <!-- controllers -->
    <div wnFlexContainer class="controllers" id="left" [justifyContent]="'center'" [alignItems]="'center'" [hidden]="scrollLeft === 0">
        <div wnText [color]="uiService.colors.WHITE" (click)="pre()">PREV</div>
    </div>

    <div wnFlexContainer class="controllers" id="right" [justifyContent]="'center'" [alignItems]="'center'" [hidden]="((scrollWidth - scrollOffsetWidth) - scrollLeft) < 1">
        <div wnText [color]="uiService.colors.WHITE" (click)="next()">NEXT</div>
    </div>

</div>

<nz-modal [nzWidth]="'1300px'" [(nzVisible)]="view" [nzTitle]=false (nzOnCancel)="cancel()" [nzFooter]="null">
    <div [ngClass]='thumbUrl' style="height:100%;position:relative;width:100%">
        <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
            <img #wistia [src]="thumbImage" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
        </div>
    </div>
    <img class="cancel" (click)='cancel()' src="assets/cancel.svg" alt="">
</nz-modal>

<!-- controllers -->
<div wnFlexContainer class="controllers" id="left" [justifyContent]="'center'" [alignItems]="'center'" [hidden]="scrollLeft === 0">
    <div wnText [color]="uiService.colors.WHITE" (click)="pre()">PREV</div>
</div>

<div wnFlexContainer class="controllers" id="right" [justifyContent]="'center'" [alignItems]="'center'" [hidden]="((scrollWidth - scrollOffsetWidth) - scrollLeft) < 1">
    <div wnText [color]="uiService.colors.WHITE" (click)="next()">NEXT</div>
</div>


<div *ngFor="let index of content">
    <div *ngIf="show" class="mini">
        <!-- <img class="mini-ico" src="assets/images/icon/preview_btn.png" (click)="onShow(index.hashed_id)" alt=""> -->
        <img class="mini-ico" [src]="index.pic" alt="" (click)="onShow(index.hashed_id)">
    </div>
</div>
