import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexContainerDirective } from './ui-foundation/directives/flex-container.directive';
import { TextDirective } from './ui-foundation/directives/text.directive';
import { VoidDirective } from './ui-foundation/directives/void.directive';
import { CarouselComponent } from './ui-foundation/components/carousel/carousel.component';
import { ButtonDirective } from './ui-foundation/directives/button.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { VideoCarouselComponent} from './ui-foundation/components/video-carousel/video-carousel.component'
import { WtvNavComponent } from './ui-foundation/components/wtv-nav/wtv-nav.component';
import { WtvHeroComponent } from './ui-foundation/components/wtv-hero/wtv-hero.component';
import { WtvMaxSliderComponent } from './ui-foundation/components/wtv-max-slider/wtv-max-slider.component';
import { WtvMiniSliderComponent } from './ui-foundation/components/wtv-mini-slider/wtv-mini-slider.component';
import { VideoModalComponent } from './ui-foundation/components/video-modal/video-modal.component'
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NgApexchartsModule } from "ng-apexcharts";
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
@NgModule({
  declarations: [
    FlexContainerDirective,
    TextDirective,
    VoidDirective,
    CarouselComponent,
    ButtonDirective,
    VideoCarouselComponent,
    WtvNavComponent,
    WtvHeroComponent,
    WtvMiniSliderComponent,
    WtvMaxSliderComponent,
    VideoModalComponent,

  ],
  imports: [
    NzUploadModule,
    CommonModule,
    CarouselModule,
    NzModalModule,
    NzIconModule,
    NzDrawerModule,
    NzCarouselModule,
    ImageCropperModule,
    NgApexchartsModule,
    NzDropDownModule,
    NzDividerModule

  ],
  exports: [
    FlexContainerDirective,
    TextDirective,
    VoidDirective,
    CarouselComponent,
    ButtonDirective,
    VideoCarouselComponent,
    WtvNavComponent,
    WtvHeroComponent,
    WtvMiniSliderComponent,
    WtvMaxSliderComponent,
    VideoModalComponent,
  ]
})
export class SharedModule { }
