<!-- <link rel="stylesheet" href="https://fast.wistia.com/embed/channel/project/xixn8wzw7z/font.css" />
<div class="wistia_channel wistia_async_xixn8wzw7z mode=inline" style="min-height:100vh;position:relative;width:100%;"></div> -->



<div class="main-banner" *ngFor="let item of content">
    <div class="banner-box">
        <div class="banner-details">
            <div class="banner-heading">
                <img class="heading-img" [src]="item.title" alt="">
            </div>
            <div class="buttons">
                <div class="play">
                    <button class="play-btn"><span><img style="width: 1em;" src="assets/play.svg" alt=""></span>&nbsp;&nbsp; <span
            style="margin-top: 2px;"> PLAY</span></button>
                </div>
                <div class="trailler">
                    <span class="action-btn"><img class="play-ico" src="assets/check.svg" alt=""></span>
                    <span class="action-btn"><img class="play-ico" src="assets/like.svg" alt=""></span>
                    <span class="action-btn"><img class="play-ico" src="assets/dislike.svg" alt=""></span>
                </div>


            </div>
        </div>
    </div>
    <img class="item" [src]="item.pic" alt="" *ngIf="!play">

    <!--  <video class="item" [style.display]="!play? 'none': 'block'" #video>
  <source src="assets/subtitle/mov_bbb.mp4" type="video/mp4">

</video> -->


    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;" *ngIf="play">
        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
            <div class="wistia_embed wistia_async_biklg9sn6r videoFoam=true" style="height:100%;position:relative;width:100%">
                <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                    <img src="https://fast.wistia.com/embed/medias/biklg9sn6r/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div>
            </div>
        </div>
    </div>

    <div class="banner-overlay item"></div>
</div>
<div class="vid-det">
    <div class="dets">
        <div class=""> <span class="category ">95% Match</span>
            <span class="age stat">16+</span>
            <span class="time stat">1hr 50m</span></div>
        <p>
            A celebrity journalist and renowned womanizer starts to rethink his life choices after he falls for a mysterious model who leads a double life.
        </p>
    </div>
    <div></div>
</div>
<div class="main-extra">
    <section class="sliderContent" *ngFor="let item of preview">
        <div class="item square">
            <!-- <div class="slider-item" *ngFor="let item of content"> -->
            <!-- <img class="images" src="https://occ-0-243-299.1.nflxso.net/dnm/api/v5/rendition/412e4119fb212e3ca9f1add558e2e7fed42f8fb4/AAAABTOj1-116yVcgKWMU2dI3GFR4x0fSkiGsqtLLeLUxRR7STaksjAqBTrYlTfrB8nIGnGvXksi0ewXAhVGg6-pLxpFOIfcpjK-pf8D5xehFZo5a6vJbo4L0AGbrzglbyUoq255QBJgRQ.jpg"
            alt="Describe Image"> -->

            <img class="images" [src]="item.pic" alt="Describe Image">
            <div class="text">
                <div class="preview-status">
                    <span class="category ">95% Match</span>
                    <span class="age stat">16+</span>
                    <span class="time stat">1hr 50m</span>
                </div>
                <div class="genre">
                    <span class="time ">Comedy</span>
                    <!-- <span class=" stat"><img class="dot" src="assets/black-circle.svg" alt=""></span> -->
                </div>
            </div>
        </div>

    </section>
</div>



<!-- <link rel="stylesheet" href="https://fast.wistia.com/embed/channel/project/xixn8wzw7z/font.css" /> -->
<!-- <div class="wistia_channel wistia_async_xixn8wzw7z mode=inline" style="min-height:100vh;position:relative;width:100%;"></div> -->
