<div class="row-stack" *ngFor="let item of [1]">
  <div class="row-heading">
    <span class="genre-title">{{ title }}</span>
  </div>
  <div class="row-container">
    <div class="pop wrapper">
      <div class="handle handlePrev active" (click)="pre()">PREV</div>
      <div class="handle handleNext active" (click)="next()">NEXT</div>

      <section
        class="sliderContent"
        (scroll)="horizScroll($event)"
        #horizScrollElem
      >
        <div class="item square" *ngFor="let item of content">
          <div
            *ngIf="
              !item.payment_required || (item.payment_required && user_paid)
            "
          >
            <!-- <div class="slider-item" *ngFor="let item of content"> -->
            <!-- <img class="images" src="https://occ-0-243-299.1.nflxso.net/dnm/api/v5/rendition/412e4119fb212e3ca9f1add558e2e7fed42f8fb4/AAAABTOj1-116yVcgKWMU2dI3GFR4x0fSkiGsqtLLeLUxRR7STaksjAqBTrYlTfrB8nIGnGvXksi0ewXAhVGg6-pLxpFOIfcpjK-pf8D5xehFZo5a6vJbo4L0AGbrzglbyUoq255QBJgRQ.jpg"
                    alt="Describe Image"> -->

            <!-- <img class="images" [src]="item.pic" alt="Describe Image"> -->
            <div class="relative" (click)="onShow(item.hashed_id)">
              <img
                class="images"
                [src]="item.thumbnail.url"
                alt="Describe Image"
              />

              <!-- <div class="title">{{item.name}}</div>
                            <div class="banner-overlay"></div> -->
            </div>
            <div class="text" (click)="showModal()">
              <div>
                <!-- <span class="play" (click)="onShow(item.hashed_id)"
                  ><img class="play-ico" src="assets/play.svg" alt=""
                /></span> -->
                <span class="play" (click)="onShow(item.hashed_id)"
                  ><img class="play-ico" src="assets/play.svg" alt=""
                /></span>

                <span class="action-btn"
                  ><img class="play-ico" src="assets/check.svg" alt=""
                /></span>
                <span class="action-btn"
                  ><img class="play-ico" src="assets/like.svg" alt=""
                /></span>
                <span class="action-btn"
                  ><img class="play-ico" src="assets/dislike.svg" alt=""
                /></span>
                <!-- <span class="action-btn down" (click)="showModal()"><img class="play-ico" src="assets/down-arrow.svg"
                      alt="">
                  </span> -->
              </div>

              <div class="preview-status">
                <!-- <span class="category ">95% Match</span>
                                <span class="age stat">16+</span> -->
                <span class="time">{{ item.name }}</span>
                <span class="stat"
                  ><img class="dot" src="assets/black-circle.svg" alt=""
                /></span>
                <span class="time stat">{{ duration(item.duration) }}</span>
              </div>

              <!-- <div class="genre">
                                <span class="time ">{{item.name}}</span>
                                <span class=" stat"><img class="dot" src="assets/black-circle.svg" alt=""></span>
                                <span class="time ">Commedy</span>
                            </div> -->
            </div>
          </div>

          <div class="pp" *ngIf="item.payment_required && !user_paid">
            <img
              class="images c"
              [src]="item.thumbnail.url"
              alt="Describe Image"
            />
            <div class="middle">
              <!-- <div class="relative"> -->
              <img
                (click)="showPay()"
                class="lock-ico"
                src="assets/lock.svg"
                alt=""
              />
              <!-- <div class="title">{{item.name}}</div>
                                <div class="banner-overlay"></div>
                            </div> -->
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<!-- <nz-modal
  [nzWidth]="'1300px'"
  [(nzVisible)]="isVisible"
  [nzTitle]="false"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="null"
>
  <ng-container *nzModalContent>
    <wn-video-modal
      [title]="'CEO CLUB'"
      [content]="ceo"
      [preview]="mini"
    ></wn-video-modal>
  </ng-container>
</nz-modal> -->

<!--
<div class="row-stack " *ngFor="let item of [1]">
  <div class="row-heading">
      <span class="genre-title">{{title}}</span>
  </div>
  <div class="row-container">
      <div class="pop wrapper">
          <div class="handle handlePrev active">PREV</div>
          <div class="handle handleNext active">NEXT</div>

          <section class="sliderContent">
              <div class="item square pp" *ngFor="let item of content">
                  <img class="images c" [src]="item.pic" alt="Describe Image">
                  <div class="middle">
                      <img (click)="showPay()" class="lock-ico" src="assets/lock.svg" alt="">
                  </div>
              </div>

          </section>
      </div>
  </div>
</div>  -->

<nz-modal
  [nzWidth]="'1300px'"
  [(nzVisible)]="show"
  [nzTitle]="false"
  (nzOnCancel)="cancel()"
  [nzFooter]="null"
>
  <div
    [ngClass]="thumbUrl"
    style="height: 100%; position: relative; width: 100%"
  >
    <div
      class="wistia_swatch"
      style="
        height: 100%;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: opacity 200ms;
        width: 100%;
      "
    >
      <img
        #wistia
        [src]="thumbImage"
        style="
          filter: blur(5px);
          height: 100%;
          object-fit: contain;
          width: 100%;
        "
        alt=""
        aria-hidden="true"
        onload="this.parentNode.style.opacity=1;"
      />
    </div>
  </div>
  <img class="cancel" (click)="cancel()" src="assets/cancel.svg" alt="" />
</nz-modal>

<nz-modal
  [nzWidth]="'1300px'"
  [(nzVisible)]="view"
  [nzTitle]="false"
  (nzOnCancel)="cancel()"
  [nzFooter]="null"
>
  <div style="padding: 2em">
    <h3>Missing payment</h3>
    <br />
    Click <a href="/home">here</a> to make a payment in order to access videos
  </div>
</nz-modal>
