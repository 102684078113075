import { Directive, ElementRef, HostListener, Input, AfterViewInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[wnButton]'
})
export class ButtonDirective implements OnChanges {

  @Input() white: boolean;
  @Input() width: string;
  @Input() borderRadius: string;
  @Input() backgroundColor: string;

  ref: ElementRef;

  constructor(element: ElementRef) {
    this.ref = element;
    // get style object
    const style = element.nativeElement.style;

    // set appearance attributes
    style.height = '38px';
    // style.backgroundColor = '#5974a6';
    style.backgroundColor = 'black';
    style.color = 'white';
    style.borderRadius = '2px';
    style.padding = '32px';
    style.fontSize = '16px';
    style.minWidth = '20px';
    style.border = '1px solid #5974a6';
    style.display = 'flex';
    style.flexDirection = 'row';
    style.justifyContent = 'flex-end';
    style.alignItems = 'center';
    style.backgroundPosition = 'center';
    style.transition = 'background 0.8s';
    style.letterSpacing = '3px';
    style.cursor = 'pointer';
  }

  ngOnChanges(): void {
    this.setWhiteAppearance();
    this.setDimensions();
    this.setBorderRadius();
    this.setBackgroundColor();
  }

  setDimensions(): void {
    if (this.width) {
      const style = this.ref.nativeElement.style;

      style.width = this.width;
    }
  }

  setBackgroundColor(): void {
    if (this.width) {
      const style = this.ref.nativeElement.style;

      style.backgroundColor = this.backgroundColor;
      style.border = `1px solid ${this.backgroundColor}`;
    }
  }

  setBorderRadius(): void {
    if (this.borderRadius) {
      const style = this.ref.nativeElement.style;

      style.borderRadius = this.borderRadius;
    }
  }

  setWhiteAppearance(): void {
    if (this.white) {
      const style = this.ref.nativeElement.style;

      style.backgroundColor = 'white';
      style.border = '1px solid #dadada';
    }
  }

  @HostListener('mousedown')
  buttonDown(): void {
    // get style object
    const style = this.ref.nativeElement.style;
    style.transform = 'scale(0.99)';
  }

  @HostListener('mouseup')
  buttonUp(): void {
    // get style object
    const style = this.ref.nativeElement.style;
    style.transform = 'scale(1)';
  }

}
