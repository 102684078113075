import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wn-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
  @Input() content: any[];
  @Input() preview: any[];
  play

  changeText
  constructor() { }

  ngOnInit(): void {
    // this.sub = Observable.interval(10000)
    // .subscribe((val) => { console.log('called'); });
  }



  async pauseOrPlay(video, play: boolean, delay: number = 0) {

    await this.delay(delay);
    play === true ? video.play() : video.pause()
    this.play = play

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
