<div class="main-banner" *ngFor="let item of content">
  <div
    class="banner-box"
    (mouseover)="pauseOrPlay(video, true, 1500); showSound()"
  >
    <div class="banner-details">
      <div class="banner-heading">
        <img class="heading-img" [src]="item.title" alt="" />
      </div>
      <div class="buttons">
        <!-- <div class="play">
                    <button class="play-btn" (click)="pauseOrPlay(video,true, 0); mute=!mute"><span><img style="width: 1em;"
                src="assets/play.svg" alt=""></span>&nbsp;&nbsp; <span style="margin-top: 2px;"> PLAY</span></button>
                </div>
                <div class="trailler">
                    <button class="trail-btn"><span>TRAILER</span></button>
                </div> -->
      </div>
    </div>
  </div>
  <img
    class="item"
    [src]="item.pic"
    alt=""
    *ngIf="!play"
    (mouseover)="pauseOrPlay(video, true, 1500)"
  />

  <video
    class="item"
    [style.display]="!play ? 'none' : 'block'"
    control
    autoplay
    #video
  >
    <source src="assets/subtitle/ceo.mp4" type="video/mp4" />
    <!-- <source src="mov_bbb.ogg" type="video/ogg"> -->
  </video>
  <div class="banner-overlay item"></div>

  <div class="sound">
    <span
      *ngIf="show"
      class="action-btn"
      (click)="pauseOrPlay(video, true, 0); mute = !mute; showSound()"
      ><img class="play-ico" src="assets/mute.svg" alt=""
    /></span>
    <span
      *ngIf="!show"
      class="action-btn"
      (click)="pauseOrPlay(video, true, 0); mute = !mute; showSound()"
      ><img class="play-ico" src="assets/volume.svg" alt=""
    /></span>
  </div>
</div>
