import { viewClassName } from '@angular/compiler';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { UiService } from 'src/app/services/ui/ui.service';

@Component({
  selector: 'wn-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent implements OnInit {
  @Input() text?: string[];
  @Input() bgColor?: string[];
  @Input() color?: string[];
  @Input() images: string[];
  @Input() lifeStyle: any[];
  @Input() hideGradient?: boolean;
  @Input() show: string;
  @Input() title: string;
  @Input() topSpace: string = '150px';
  @Input() bottomSpace: string;
  @Input() height: string;
  scrollLeft: number;
  scrollWidth: number;
  scrollOffsetWidth: number;
  subscription: Subscription;
  auto: any;

  @ViewChild('horizScrollElem', { static: false })
  horizScrollRef?: ElementRef<any>;
  currentIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(public uiService: UiService) {}

  ngOnInit(): void {
    //emit value in sequence every 10 second
    // const source = interval(5000);
    // this.subscription = source.subscribe(val => this.horizScrollRef.nativeElement.scrollLeft += this.scrollOffsetWidth);
  }

  moveScrollRight() {
    if (this.scrollLeft + this.scrollOffsetWidth === this.scrollWidth) {
      return;
    }

    this.horizScrollRef.nativeElement.scrollLeft += this.scrollOffsetWidth;
  }

  moveScrollLeft() {
    if (this.scrollLeft === 0) {
      return;
    }
    this.horizScrollRef.nativeElement.scrollLeft -= this.scrollOffsetWidth;
  }

  horizScroll(args: any) {
    this.scrollLeft = args.target.scrollLeft;
    this.scrollOffsetWidth = args.target.offsetWidth;
    this.scrollWidth = args.target.scrollWidth;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const el = this.horizScrollRef?.nativeElement;
      this.scrollLeft = el?.scrollLeft ?? this.scrollLeft;
      this.scrollOffsetWidth = el?.offsetWidth ?? this.scrollOffsetWidth;
      this.scrollWidth = el?.scrollWidth ?? this.scrollWidth;
    }, 1000);

    const obs$ = interval(5000);
    obs$.subscribe((d) => {
      for (let i = 0; i < this.lifeStyle.length; i++) {
        console.log(this.lifeStyle, 'hi');
        if (i < this.lifeStyle.length) {
          const source = interval(10000);
          this.subscription = source.subscribe(
            (val) =>
              (this.horizScrollRef.nativeElement.scrollLeft +=
                this.scrollOffsetWidth)
          );
        } else console.log(this.lifeStyle, 'hello');
        this.horizScrollRef.nativeElement.scrollLeft -= this.scrollWidth;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
