import { Directive, AfterViewInit, Input, ElementRef } from '@angular/core';
import { color } from '../constants/colors';

@Directive({
  selector: '[wnText]'
})
export class TextDirective implements AfterViewInit {

  @Input() fontSize: string;
  @Input() fontWeight: string;
  @Input() color: string;
  @Input() block: boolean;
  @Input() truncate: boolean;
  @Input() lineHeight: boolean;
  @Input() textAlign:string;
 
  ref: ElementRef;

  constructor(element: ElementRef) {
    // create element ref
    this.ref = element;

    // get style object
    const style = element.nativeElement.style;

    // set properties
    style.display = 'inline-block';
    style.color = color.textColor;
    style.fontStyle = 'normal';

  }

  ngAfterViewInit(): void {
    // set input properties
    this.setinputProperties();
    this.setupTruncate();
    this.setLineHeight();
  }

  setLineHeight(): void {
    if (this.lineHeight) {
      // get style property
      const style = this.ref.nativeElement.style;

      style.lineHeight = this.lineHeight;
    }
  }

  setupTruncate(): void {
    // get style property
    const style = this.ref.nativeElement.style;

    if (this.truncate) {
      style.textOverflow = 'ellipsis';
      style.overflow = 'hidden';
      style.whiteSpace = 'nowrap';
    }
  }

  setinputProperties(): void {
    // get style property
    const style = this.ref.nativeElement.style;

    if (this.fontSize) {
      style.fontSize = this.fontSize;
    }
    if (this.textAlign) {
      style.textAlign = this.textAlign;
    }

    if (this.fontWeight) {
      style.fontWeight = this.fontWeight;
    }

    if (this.color) {
      style.color = this.color;
    }

    if (this.block) {
      style.dispaly = this.block ? 'block' : 'inline-block';
    }
  }

}

