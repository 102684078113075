import { ElementRef, Input, AfterViewInit, Directive } from '@angular/core';

import camelcase from 'camelcase';
import { dimensions } from '../constants/dimensions';

@Directive()
export abstract class Container implements AfterViewInit {

  @Input() hasHeader: boolean;
  @Input() hasLeftSideNavigation: boolean;
  @Input() wrapper: boolean;
  @Input() leftGutter: string;
  @Input() rightGutter: string;
  @Input() height: string;
  @Input() width: string;
  @Input() maxHeight: string;
  @Input() maxWidth: string;
  @Input() minHeight: string;
  @Input() minWidth: string;
  @Input() backgroundColor: string;
  @Input() color: string;
  @Input() padding: string;
  @Input() overflowX: string;
  @Input() overflowY: string;
  @Input() marginLeft: string;
  @Input() marginRight: string;
  @Input() marginTop: string;
  @Input() marginBottom: string;
  @Input() borderRadius: string;

  protected ref: ElementRef;

  constructor(element: ElementRef) {
    this.ref = element;
  }

  ngAfterViewInit() {
    // set header padding
    this.setHeaderPadding();

    // set left side navigation padding
    this.setLeftSideNavigationPadding();

    // set wrapper
    this.setWrapperProperties();

    // set gutter
    this.setPadding();

    // set height
    this.setDimensions();

    // set color
    this.setColor();

    // set overflow
    this.setOverFlow();

    this.setMargin();

    this.setBorderRadius();
  }

  private setBorderRadius() {
    if (this.borderRadius) {
      const style = this.ref.nativeElement.style;
      style.borderRadius = this.borderRadius;
    }
  }

  private setMargin() {
    const style = this.ref.nativeElement.style;

    if (this.marginLeft) {
      style.marginLeft = this.marginLeft;
    }

    if (this.marginRight) {
      style.marginRight = this.marginRight;
    }

    if (this.marginTop) {
      style.marginTop = this.marginTop;
    }

    if (this.marginBottom) {
      style.marginBottom = this.marginBottom;
    }
  }

  private setOverFlow() {
    const style = this.ref.nativeElement.style;
    if (this.overflowX) {
      // set overflow
      style.overflowX = this.overflowX;
    }

    if (this.overflowY) {
      // set overflow
      style.overflowY = this.overflowY;
    }
  }

  private setHeaderPadding() {
    if (this.hasHeader) {
      const style = this.ref.nativeElement.style;

      // set padding
      style.paddingTop = dimensions.toolbarHeight;
    }
  }

  private setDimensions() {
    const style = this.ref.nativeElement.style;

    if (this.height) {
      style.height = this.height;
    }

    if (this.width) {
      style.width = this.width;
    }

    if (this.maxHeight) {
      style.maxHeight = this.maxHeight;
    }

    if (this.maxWidth) {
      style.maxWidth = this.maxWidth;
    }

    if (this.minHeight) {
      style.minHeight = this.minHeight;
    }

    if (this.minWidth) {
      style.minWidth = this.minWidth;
    }
  }

  private setColor() {
    const style = this.ref.nativeElement.style;

    if (this.color) {
      style.color = this.color;
    }

    if (this.backgroundColor) {
      style.backgroundColor = this.backgroundColor;
    }
  }

  private setLeftSideNavigationPadding() {
    if (this.hasLeftSideNavigation) {
      const style = this.ref.nativeElement.style;

      // set padding
      style.paddingLeft = dimensions.leftSideNavigationWidth;
    }
  }

  private setWrapperProperties() {
    if (this.wrapper) {
      const style = this.ref.nativeElement.style;

      // set wrapper properties
      style.height = '100vh';
      style.backgroundColor = 'white';
      style.overflowX = 'hidden';
      style.overflowY = 'auto';
      style.position = 'relative';
    }
  }

  private setPadding() {
    const style = this.ref.nativeElement.style;

    if (this.leftGutter) {
      style.paddingLeft = this.leftGutter;
    }

    if (this.rightGutter) {
      style.paddingRight = this.rightGutter;
    }

    if (this.padding) {
      style.padding = this.padding;
    }

  }

  protected getToolbarHeightRelativeToWindow() {
    // get window height
    const windowHeight = window.innerHeight;
    const headerHeight = Number(dimensions.toolbarHeight.substring(
      0, dimensions.toolbarHeight.length - 2
    ));

    return Math.round((headerHeight / windowHeight) * 100);
  }

  protected getSideNavigationWidthRelativeToWindow() {
    // get window height
    const windowHeight = window.innerWidth;
    const headerHeight = Number(dimensions.leftSideNavigationWidth.substring(
      0, dimensions.leftSideNavigationWidth.length - 2
    ));

    return Math.round((headerHeight / windowHeight) * 100);
  }

  protected convertPercentageToPixel(value: number, axis: string) {
    const parent = axis === 'height' ? window.innerHeight : window.innerWidth;

    return Math.round((value / 100) * parent);
  }

  protected getParentDimensions(element: ElementRef) {
    // get parent node
    const parent = element.nativeElement.parentElement;

    return {
      width: parent.clientWidth,
      height: parent.clientHeight
    };
  }

  protected getIntputs(element: any): { name: string, value: any }[] {
    // get attributes fom node
    const attributes = element.attributes;

    // get inputs
    const inputKeys = Object.keys(attributes).filter((a: string) => {
      if (attributes[a].localName.search(/(ng-reflect)/g) >= 0) {
        return a;
      }
    });

    const inputs = inputKeys.map((key: string) => {
      const name = camelcase(attributes[key].localName.replace('ng-reflect-', ''));

      return {
        name,
        value: attributes[key].value
      };
    });

    return inputs;
  }
}
