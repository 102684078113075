import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { ApiService } from 'app/wmg-services/api.service';
// import { iSubscription } from 'app/lib/models/users.model';
// import { SubObjService } from 'app/wmg-services/sub-obj.service';
// import { AuthService } from 'app/wmg-auth/auth.service';


@Component({
  selector: 'wn-wtv-mini-slider',
  templateUrl: './wtv-mini-slider.component.html',
  styleUrls: ['./wtv-mini-slider.component.scss']
})
export class WtvMiniSliderComponent implements OnInit {
  @Input() title: string;
  @Input() text: any;
  @Input() content: any[];
  thumbUrl: string = "";
  thumbImage: string = "";
  isVisible = false;
  view = false; show
  scrollLeft: number;
  scrollWidth: number;
  scrollOffsetWidth: number;

  @Input() user_paid;

  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;

  @ViewChild('horizScrollElem', { static: false }) horizScrollRef?: ElementRef<any>;

  constructor() { }

  pre(){
    if (this.scrollLeft === 0) { return; }
    this.horizScrollRef.nativeElement.scrollLeft -= 500;
  }
  next() {
    if (this.scrollLeft + this.scrollOffsetWidth === this.scrollWidth) { return; }

    this.horizScrollRef.nativeElement.scrollLeft += 500;
  }
  horizScroll(args: any) {
    this.scrollLeft = args.target.scrollLeft;
    this.scrollOffsetWidth = args.target.offsetWidth;
    this.scrollWidth = args.target.scrollWidth;
  }


  ceo = [
    { pic: 'assets/subtitle/latenight.jpg' }
  ]
  mini = [
    { pic: 'assets/subtitle/sheis.jpg' },
    { pic: 'assets/subtitle/whydidimarry.jpg' },
    { pic: 'assets/subtitle/news.jpg' },
    { pic: 'assets/subtitle/girlfriends.jpg' },
    { pic: 'assets/subtitle/vampire.jpg' },
    { pic: 'assets/subtitle/bigbang.jpg' },
    { pic: 'assets/subtitle/erin.jpg' },
    { pic: 'assets/subtitle/nobodyfool.jpg' },
    { pic: 'assets/subtitle/zoro.jpg' },
  ]

  showModal(): void {
    this.isVisible = true;
  }
  showPay(): void {
    this.view = true;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
  ngOnInit(): void {

  }


  cancel(): void {
    console.log('Button cancel clicked!');
    this.view = false;
    this.show = false;
  }

  onShow(hashed_id: string): void {
    console.log('Button show clicked!');
    this.show = true;
    this.thumbUrl = `wistia_embed wistia_async_${hashed_id}  videoFoam=true`
    this.thumbImage = `https://fast.wistia.com/embed/medias/${hashed_id}/swatch`

  }

  duration(seconds) {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " secs") : "";
    return hDisplay + mDisplay + sDisplay;
}
}
