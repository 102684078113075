<div class="row-stack " *ngFor="let item of [1]">
    <div class="row-heading">
        <span class="genre-title">MOST WATCHED</span>
    </div>
    <div class="row-container">
        <div class="pop">
            <div class="handle handlePrev active">PREV</div>
            <div class="handle handleNext active">NEXT</div>
            <div class="slider">

                <div class="sliderContent">
                    <div class="slider-item" *ngFor="let item of content">
                        <div class="box">
                            <div class="box-item-1">
                                <img class="item" [src]="item.pic" alt="" (click)="showModal()">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<nz-modal [nzWidth]="'1300px'" [(nzVisible)]="isVisible" [nzTitle]=false (nzOnCancel)="handleCancel()" [nzFooter]="null">
  <ng-container *nzModalContent>

    <!-- <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
          <div class="wistia_embed wistia_async_biklg9sn6r videoFoam=true" style="height:100%;position:relative;width:100%">
              <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img src="https://fast.wistia.com/embed/medias/biklg9sn6r/swatch" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" /></div>
          </div>
      </div>
  </div> -->
  </ng-container>
</nz-modal>
